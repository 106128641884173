import React from 'react'

import Layout from "../components/layout"
import Form from '../components/form'
import Seo from "../components/seo"
import Blurbs from '../components/blurbs'

// import { css } from "@emotion/core"

const benefits = [
    {
        title: "Plus de mandats",
        desc: "Multipliez le nombre de mandats auxquels vous participez et votre chiffre d'affaires",
    },
    {
        title: "Plus de visibilité",
        desc: "Mettez votre entreprise en avant auprès de nouvelles audiences PME",
    },
    {
        title: "Plus de réseau",
        desc: "Connectez vous avec d'autres experts digitaux et freelances en Suisse romande",
    },
]

const AgencesPage = (props) => {

    return (
        <Layout>
            <Seo title="Agence multimedia, creatives et digitales en Suisse romande" />

            <div className="container">
                <div className="hero-section">
                    <h1>Agence digitale ou multimédia en Suisse romande ?</h1>
                    <p>Vous êtes une entreprise du digital en Suisse romande, avec des services tels que la production photo, le tournage vidéo, le développement web ou applicatif, le web-design, le marketing web... ? Nous vous permettons de trouver plus de mandats en étant référencés sur notre plateforme.</p>
                </div>

                <Blurbs
                    items={benefits} 
                    numbered 
                />

                <div className="form-wrapper">
                    <h2>Rejoindre le réseau de prestataires multimédia et digitaux en Suisse romande</h2>

                    <Form 
                        formName="inscription-agence"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default AgencesPage